import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from 'react';

import Parse from '../parse';
import { LEGSysSensor, useSensorService } from '../schemas/LEGSysSensor';
import { useSensorEventService } from '../schemas/SensorEvent';
import useErrorHandling from '../hooks/useErrorHandling';
import {
  UserRolesColumns,
  UserRolesRequest,
  TableRequest,
  TableResponse,
  TabletEnrolledColumns,
  TabletIndexColumns,
  TabletIndexRequest,
} from '../types/tables';
import {
  AddOrRemoveSensorResponse,
  RoleOperation,
  TeleFootXRoles,
} from '../types/cloud';
import { useLogging } from './LoggingProvider';
import { Site, useSiteService } from '../schemas/Site';
import { useTabletService } from '../schemas/Tablet';
import { SensorOperation } from '../pages/authorized/SensorManagement/SensorManagement';

/**
 * Known cloud functions that can be run
 */
export const KNOWN_CLOUD_FUNCTIONS = {
  PING: 'ping',
  SWAP_SENSOR: 'SwapSensor',
  GRANT_OR_REVOKE_INTERNAL_ROLE: 'GrantOrRevokeInternalRole',
  GRANT_OR_REVOKE_EXTERNAL_ROLE: 'GrantOrRevokeExternalRole',
  GET_USERS_WITH_ROLE: 'GetUsersWithRole',
  ADD_SITE: 'AddSite',
  USER_ROLES_TABLE: 'UserRolesTable',
  TABLET_INDEX_TABLE: 'TabletIndexTable',
  TABLET_ENROLLED_TABLE: 'TabletEnrolledTable',
  ADD_EDIT_TABLET: 'AddEditTablet',
  ADD_OR_REMOVE_SENSOR: 'AddOrRemoveSensor',
  GET_ACTIVE_SENSORS: 'GetActiveSensors',
} as const;

interface AddSiteParams {
  siteId: string;
}

interface AddEditTabletParams {
  tabletIMEI: string;
  deviceId: string;
  sensorId: string;
  siteId: string;
}

interface AddOrRemoveSensorParams {
  sensors: { mac: string }[];
  operation: SensorOperation;
}

interface RoleOperationParams {
  userId: string;
  operation: RoleOperation;
  roles: TeleFootXRoles[];

  // internal or external
  internal: boolean;
}

export type CloudContent = {
  siteService: ReturnType<typeof useSiteService>;
  tabletService: ReturnType<typeof useTabletService>;
  sensorService: ReturnType<typeof useSensorService>;
  sensorEventService: ReturnType<typeof useSensorEventService>;
  cloudService: ReturnType<typeof useCloudService>;
};

const CloudContext = createContext<CloudContent>(undefined!);

const CloudProvider = ({ children }: { children: ReactNode }) => {
  const siteService = useSiteService();
  const tabletService = useTabletService();
  const sensorService = useSensorService();
  const sensorEventService = useSensorEventService();
  const cloudService = useCloudService();

  return (
    <CloudContext.Provider
      value={{
        siteService,
        tabletService,
        sensorService,
        sensorEventService,
        cloudService,
      }}>
      {children}
    </CloudContext.Provider>
  );
};

const useCloudContext = () => useContext(CloudContext);

export { useCloudContext, CloudProvider };

function useCloudService() {
  const logger = useLogging(useCloudService.name);

  // private
  const run = useErrorHandling(
    async <T,>(
      functionName: string,
      params?: JSON,
      log: boolean = true,
    ): Promise<T> => {
      if (log) {
        logger.debug(
          `Running cloud function ${functionName} with params ${JSON.stringify(params)}`,
        );
      }
      return Parse.Cloud.run(functionName, params);
    },
    [logger],
  );

  const prepareRquest = useCallback(<T extends object>(req: T) => {
    return JSON.parse(JSON.stringify(req));
  }, []);

  /**
   * Quick helper function to prepare a request for the cloud function
   * @param req The request to prepare
   * @returns A JSON object that can be sent to the cloud function
   */
  const prepareTableRquest = useCallback(
    <T extends TableRequest>(req: T) => {
      return prepareRquest(req);
    },
    [prepareRquest],
  );

  const addSite = useCallback(
    async (params: AddSiteParams) => {
      const res = await run<Site>(
        KNOWN_CLOUD_FUNCTIONS.ADD_SITE,
        prepareRquest(params),
      );
      return res;
    },
    [prepareRquest, run],
  );

  const addEditTablet = useCallback(
    async (params: AddEditTabletParams) => {
      const res = await run<void>(
        KNOWN_CLOUD_FUNCTIONS.ADD_EDIT_TABLET,
        prepareRquest(params),
      );
      return res;
    },
    [prepareRquest, run],
  );

  const addOrRemoveSensor = useCallback(
    async (params: AddOrRemoveSensorParams) => {
      const res = run<AddOrRemoveSensorResponse>(
        KNOWN_CLOUD_FUNCTIONS.ADD_OR_REMOVE_SENSOR,
        prepareRquest(params),
      );
      return res;
    },
    [prepareRquest, run],
  );

  const getActiveSensors = useCallback(async () => {
    const res = run<LEGSysSensor[]>(KNOWN_CLOUD_FUNCTIONS.GET_ACTIVE_SENSORS);
    return res;
  }, [run]);

  const getServerTime = useCallback(async (): Promise<Date> => {
    const time = await run<string>(KNOWN_CLOUD_FUNCTIONS.PING);
    return new Date(time);
  }, [run]);

  const getUsersWithRole = useCallback(
    async (role: TeleFootXRoles) => {
      const res = await run<Promise<Array<Parse.Object<Parse.Attributes>>>>(
        KNOWN_CLOUD_FUNCTIONS.GET_USERS_WITH_ROLE,
        prepareRquest({ role }),
      );
      return res;
    },
    [prepareRquest, run],
  );

  const requestUserRolesTable = useCallback(
    async (req: UserRolesRequest) => {
      const res = await run<TableResponse<UserRolesColumns>>(
        KNOWN_CLOUD_FUNCTIONS.USER_ROLES_TABLE,
        prepareTableRquest(req),
      );

      return res;
    },
    [run, prepareTableRquest],
  );

  const grantOrRevokeRoles = useCallback(
    async (params: RoleOperationParams) => {
      const { internal, ...req } = params;
      const res = await run(
        internal
          ? KNOWN_CLOUD_FUNCTIONS.GRANT_OR_REVOKE_INTERNAL_ROLE
          : KNOWN_CLOUD_FUNCTIONS.GRANT_OR_REVOKE_EXTERNAL_ROLE,
        prepareRquest(req),
      );

      return res;
    },
    [run, prepareRquest],
  );

  const requestTabletIndexTable = useCallback(
    async (req: TabletIndexRequest) => {
      const res = await run<TableResponse<TabletIndexColumns>>(
        KNOWN_CLOUD_FUNCTIONS.TABLET_INDEX_TABLE,
        prepareTableRquest(req),
      );

      return res;
    },
    [run, prepareTableRquest],
  );

  const requestTabletEnrolledTable = useCallback(
    async (req: TabletIndexRequest) => {
      const res = await run<TableResponse<TabletEnrolledColumns>>(
        KNOWN_CLOUD_FUNCTIONS.TABLET_ENROLLED_TABLE,
        prepareTableRquest(req),
      );

      return res;
    },
    [run, prepareTableRquest],
  );

  return useMemo(
    () => ({
      getServerTime,
      getUsersWithRole,
      addSite,
      requestTabletIndexTable,
      requestTabletEnrolledTable,
      addEditTablet,
      addOrRemoveSensor,
      getActiveSensors,
      requestUserRolesTable,
      grantOrRevokeRoles,
    }),
    [
      getServerTime,
      getUsersWithRole,
      addSite,
      requestTabletIndexTable,
      requestTabletEnrolledTable,
      addEditTablet,
      addOrRemoveSensor,
      getActiveSensors,
      requestUserRolesTable,
      grantOrRevokeRoles,
    ],
  );
}
