import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../router';
import { useLoading } from './LoadingProvider';
import Parse from 'parse';
import Popup from '../components/atoms/Popup/Popup';
import { StyleSheet, ThemeContent, useThemedComponent } from './ThemeProvider';
import i18n from '../translations';
import { TeleFootXRoles } from '../types/cloud';
import { LoggingContext, useLogging } from './LoggingProvider';

const INACTIVITY_WARNING_TIME = 300_000; // 5 minutes in milliseconds
const CHECK_INACTIVITY_INTERVAL = 1000; // 1 second in milliseconds

interface AuthContextContent {
  user: Parse.User | undefined;
  login: (data: Parse.User<Parse.Attributes>) => Promise<void>;
  logout: () => Promise<void>;
  devLogin: (username: string, password: string) => Promise<void>;
  roles: TeleFootXRoles[] | undefined;
  setInactivityCountdown: React.Dispatch<React.SetStateAction<boolean>>;
  isSecurityOfficer: boolean;
}

const AuthContext = createContext<AuthContextContent>(undefined!);

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<Parse.User | undefined>(
    Parse.User.current() ?? undefined,
  );
  const navigate = useNavigate();
  const { styles } = useThemedComponent([inactivityStyles]);
  const { setLoaderDemensions } = useLoading();
  // get the session expiration time
  const [sessionExpiration, setSessionExpiration] = useState<Date>();
  // when the session is about to expire, show the inactivity warning
  const [inactivityCountdown, setInactivityCountdown] =
    useState<boolean>(false);
  // local state to dismiss the inactivity warning
  const [dismissInactivity, setDismissInactivity] = useState<boolean>(false);

  const location = useLocation();

  const [roles, setRoles] = useState<TeleFootXRoles[]>();
  const {
    setUser: setLoggingUser,
    setRoles: setLoggingRoles,
    setSessionId: setLoggingSessionId,
  } = useContext(LoggingContext);
  const logger = useLogging(AuthProvider.name);

  const getUserRoles = useCallback(async () => {
    try {
      const roles = await Parse.Cloud.run('GetRolesForUser');
      setRoles(roles);

      return roles;
    } catch (e) {
      logger.error('Failed to get user roles', e);
    }
  }, [logger]);

  useEffect(() => {
    // set the loader demensions for the  main element
    setLoaderDemensions({ width: '95%', height: '92%' });
    if (user) {
      setLoggingUser(user);
      getUserRoles().then((roles: TeleFootXRoles[]) => setLoggingRoles(roles));
    }
  }, [
    getUserRoles,
    setLoaderDemensions,
    setLoggingRoles,
    setLoggingUser,
    user,
  ]);

  // get the session expiration time
  useEffect(() => {
    if (!user) {
      return;
    }
    const getExpiration = async () => {
      const session = await Parse.Session.current();
      setLoggingSessionId(session.id);
      setSessionExpiration(session.get('expiresAt'));
    };
    getExpiration();
  }, [setLoggingSessionId, user]);

  // need to show inactivy 5 minutes before the session expires
  useEffect(() => {
    if (!sessionExpiration) {
      return;
    }

    const interval = setInterval(() => {
      const currentTime = new Date();
      const timeDiff = sessionExpiration.getTime() - currentTime.getTime();
      if (timeDiff < INACTIVITY_WARNING_TIME) {
        setInactivityCountdown(true);
      }
    }, CHECK_INACTIVITY_INTERVAL);

    return () => clearInterval(interval);
  }, [sessionExpiration]);

  const logout = useCallback(async () => {
    try {
      await Parse.User.logOut();
    } catch (e) {
      // if the session token is invalid, the user is already logged out
      if (
        !(
          e instanceof Parse.Error &&
          e.code === Parse.Error.INVALID_SESSION_TOKEN
        )
      ) {
        // if not, log the error
        logger.warn('Unable to log out', e);
      }
    }
    setUser(undefined);
  }, [logger]);

  // if the user is inactive for INACTIVITY_TIME_CHECK, log them out
  useEffect(() => {
    if (!inactivityCountdown || !sessionExpiration) {
      return;
    }
    // get the time left before the session expires
    const currentTime = new Date();
    const timeDiff = sessionExpiration.getTime() - currentTime.getTime();

    const interval = setInterval(() => {
      logout();
    }, timeDiff);

    return () => clearInterval(interval);
  }, [inactivityCountdown, logout, sessionExpiration]);

  const login = useCallback(
    async (data: Parse.User) => {
      try {
        setUser(data);
        navigate(ROUTES.HOME.path);
      } catch (error) {
        logger.error('Error logging in', error);
      }
    },
    [logger, navigate],
  );

  const devLogin = useCallback(
    async (username: string, password: string) => {
      try {
        const userSignedIn = await Parse.User.logIn(username, password);
        await login(userSignedIn);
      } catch (e) {
        logger.error('unable to log in', username, e);
      }
    },
    [logger, login],
  );

  // if the user is not logged in (or in an app redirect page), redirect to the login page
  useEffect(() => {
    if (
      !user &&
      location.pathname !== ROUTES.OAUTH_MICROSOFT_APP_REDIRECT.path
    ) {
      navigate(ROUTES.LOGIN.path);
      // set the loader demensions for the login page
      setLoaderDemensions({ width: '100%', height: '100%' });
    }
  }, [location.pathname, navigate, setLoaderDemensions, user]);

  const isSecurityOfficer = useMemo(() => {
    return roles?.includes(TeleFootXRoles.SecurityOfficer) ?? false;
  }, [roles]);

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        devLogin,
        roles,
        setInactivityCountdown,
        isSecurityOfficer,
      }}>
      {children}
      {inactivityCountdown && (
        <Popup
          onClose={() => {}}
          open={inactivityCountdown && !dismissInactivity}
          xIcon={false}>
          <div style={styles.container}>
            <h1>{i18n.t('sessionExpire')}</h1>
            <p>
              {i18n.t('youWillBe', {
                seconds: sessionExpiration
                  ? // format to seconds
                    (
                      (sessionExpiration?.getTime() - new Date().getTime()) /
                      1000
                    ).toFixed(0)
                  : 0,
              })}
            </p>
            <button
              style={styles.button}
              onClick={() => setDismissInactivity(true)}>
              {i18n.t('dismiss')}
            </button>
          </div>
        </Popup>
      )}
    </AuthContext.Provider>
  );
};
const useAuth = () => {
  return useContext(AuthContext);
};

export { useAuth, AuthProvider };

const inactivityStyles = (theme: ThemeContent): StyleSheet => ({
  container: {
    backgroundColor: theme.colors.white,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
    gap: 20,
  },
  button: {
    backgroundColor: theme.colors.primary,
    color: theme.colors.white,
    padding: '10px 20px',
    borderRadius: 5,
    cursor: 'pointer',
    border: 'none',
  },
});
