import React, { useEffect } from 'react';
import Parse from 'parse';
import { useAuth } from '../../providers/AuthProvider';
import { useLogging } from '../../providers/LoggingProvider';

export default function Google() {
  const [error, setError] = React.useState('');
  const { login } = useAuth();
  const logger = useLogging(Google.name);

  useEffect(() => {
    const url = new URL(window.location.href);
    const redirectUri = url.toString().split('?')[0];
    const code = url.searchParams.get('code');
    if (!code) {
      setError('No code found');
      return;
    }
    Parse.Cloud.run('GoogleAuthCodeLogin', {
      code,
      redirectUri,
    })
      .then(authData => {
        const user = new Parse.User();
        user
          .linkWith('google', {
            authData,
          })
          .then(async () => {
            await login(user);
          })
          .catch(e => {
            logger.error(e);
            setError(e.message as string);
          });
      })
      .catch(e => {
        logger.error(e);
        setError(e.message as string);
      });
  }, [logger, login]);
  return (
    <div>
      <h1>Google...</h1>
      <p>{error}</p>
    </div>
  );
}
