import i18n from '../../../translations';
import { type CustomColumnDefintion } from '../../../types/tables';

/**
 * The columns definition for the set user roles page.
 * This is the order of the columns in the table and any customizations
 */
export const INTERNAL_ROLES_COLUMNS: CustomColumnDefintion[] = [
  {
    column: 'user',
    headerName: i18n.t('internalUser'),
  },
  { column: 'dateAdded', headerName: i18n.t('dateAdded') },
  {
    column: 'role',
    headerName: i18n.t('role'),
  },
];

/**
 * The columns definition for the set user roles page.
 * This is the order of the columns in the table and any customizations
 */
export const EXTERNAL_ROLES_COLUMNS: CustomColumnDefintion[] = [
  {
    column: 'user',
    headerName: i18n.t('externalUser'),
  },
  { column: 'dateAdded', headerName: i18n.t('dateAdded') },
  {
    column: 'role',
    headerName: i18n.t('role'),
  },
];
