import { useMemo } from 'react';
import useErrorHandling from '../hooks/useErrorHandling';
import Parse from '../parse';
import {
  SensorEvent,
  SensorEventCategory,
  SensorEventTypeHistory,
} from './SensorEvent';

/**
- Stores general sensor information used in the study
- Created by portal
- deactivatedAt is set when the sensor gets removed
 */
export interface SensorAttributes {
  createdBy: Parse.User;
  mac: string;
  deactivatedAt?: Date;
  // serialNumber: string;
  // batteryNumber?: string;
  // weldNumber?: string;
}

export const classname = 'LEGSysSensor';

export class LEGSysSensor extends Parse.Object<SensorAttributes> {
  constructor(attributes: SensorAttributes) {
    super(classname, attributes);
  }
}

Parse.Object.registerSubclass(classname, LEGSysSensor);

export function useSensorService() {
  const getAllActiveSensors = useErrorHandling(async (): Promise<
    LEGSysSensor[]
  > => {
    return [];
  }, []);

  const getAvailableSensors = useErrorHandling(async (): Promise<
    LEGSysSensor[]
  > => {
    const availableSensors: LEGSysSensor[] = [];
    const sensorEventQuery = new Parse.Query(SensorEvent);
    sensorEventQuery.descending('createdAt');
    sensorEventQuery.include('sensor');
    sensorEventQuery.equalTo('category', SensorEventCategory.HISTORY);

    const sensorQuery = new Parse.Query(LEGSysSensor);
    sensorQuery.doesNotExist('deactivatedAt');
    const activeSensors = await sensorQuery.findAll();
    await Promise.all(
      activeSensors.map(async (sensor: LEGSysSensor) => {
        sensorEventQuery.equalTo('sensor', sensor);
        const latestSensorEvent = await sensorEventQuery.first();

        if (!latestSensorEvent) {
          // if no event found --- brand new sensor
          availableSensors.push(sensor);
        } else {
          if (
            latestSensorEvent.get('type') === SensorEventTypeHistory.UNPAIRED
          ) {
            availableSensors.push(sensor);
          }
        }
      }),
    );
    return availableSensors;
  }, []);

  return useMemo(
    () => ({
      getAllActiveSensors,
      getAvailableSensors,
    }),
    [getAllActiveSensors, getAvailableSensors],
  );
}
