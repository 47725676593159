import {
  type ThemeContent,
  type StyleSheet,
} from '../../../providers/ThemeProvider';

export default (theme: ThemeContent): StyleSheet => ({
  nav: {
    backgroundColor: theme.colors.primary,
  },
  ul: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '10%',
  },
  li: {
    marginTop: '15%',
    marginBottom: '15%',
    color: theme.colors.white,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
});
