import { StyleSheet } from '../../../providers/ThemeProvider';

export const commonFormStyles = (): StyleSheet => ({
  betweenInput: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  formContainer: {
    width: '45%',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    marginTop: '5%',
    gap: '2%',
  },
});
