import React from 'react';
import { ROUTES } from '../../../router';
import { useThemedComponent } from '../../../providers/ThemeProvider';
import SideBarStyles from './SideBarStyles';
import { ReactComponent as HomeIcon } from '../../../assets/Home.svg';
import { ReactComponent as UsersIcon } from '../../../assets/Users.svg';
import { ReactComponent as SensorIcon } from '../../../assets/Sensor.svg';
import { ReactComponent as TabletIcon } from '../../../assets/Tablet.svg';
import { ReactComponent as SiteIcon } from '../../../assets/Site.svg';
import { useAuth } from '../../../providers/AuthProvider';
import { NavLink } from 'react-router-dom';

interface SideBarProps {
  style?: React.CSSProperties;
}

const SideBar = (props: SideBarProps) => {
  const { styles } = useThemedComponent([SideBarStyles]);
  const { roles, isSecurityOfficer } = useAuth();

  const SideBarItem = ({
    path,
    children,
  }: {
    path: string;
    children: React.ReactNode;
  }) => {
    return (
      <li style={{ ...styles.li }}>
        <NavLink
          to={path}
          style={({ isActive }) => {
            return {
              padding: '50%',
              borderRadius: '20%',
              backgroundColor: isActive ? '#C5D0DE30' : 'transparent',
            };
          }}>
          {children}
        </NavLink>
      </li>
    );
  };

  return (
    <nav style={{ ...styles.nav, ...props.style }}>
      <ul style={{ ...styles.ul }}>
        {roles?.length === 0 ? (
          <></>
        ) : isSecurityOfficer ? (
          <SideBarItem path={ROUTES.SET_ROLES.path}>
            <UsersIcon />
          </SideBarItem>
        ) : (
          <>
            <SideBarItem path={ROUTES.HOME.path}>
              <HomeIcon />
            </SideBarItem>
            <SideBarItem path={ROUTES.SET_ROLES.path}>
              <UsersIcon />
            </SideBarItem>
            <SideBarItem path={ROUTES.ADD_SENSOR.path}>
              <SensorIcon />
            </SideBarItem>
            <SideBarItem path={ROUTES.TABLET_INDEX.path}>
              <TabletIcon />
            </SideBarItem>
            <SideBarItem path={ROUTES.ADD_SITE.path}>
              <SiteIcon />
            </SideBarItem>
          </>
        )}
      </ul>
    </nav>
  );
};

export default SideBar;
