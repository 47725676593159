import { useMemo } from 'react';
import useErrorHandling from '../hooks/useErrorHandling';
import Parse from '../parse';

export interface SiteAttributes {
  createdBy: Parse.User;
  name: string;
}

export const className = 'Site';

export class Site extends Parse.Object<SiteAttributes> {
  constructor(attributes: SiteAttributes) {
    super(className, attributes);
  }
}

Parse.Object.registerSubclass(className, Site);

export function useSiteService() {
  const getSiteById = useErrorHandling(async (id: string): Promise<Site> => {
    const siteQuery = new Parse.Query(Site);
    siteQuery.equalTo('objectId', id);
    const site = await siteQuery.first();
    if (!site) {
      throw new Error('Site not found');
    }
    return site;
  }, []);

  const getAllSites = useErrorHandling(async (): Promise<Site[]> => {
    const siteQuery = new Parse.Query(Site);
    return await siteQuery.findAll();
  }, []);

  return useMemo(
    () => ({
      getSiteById,
      getAllSites,
    }),
    [getSiteById, getAllSites],
  );
}
