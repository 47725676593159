import i18n from '../../../translations';
import { CustomColumnDefintion } from '../../../types/tables';

/**
 * The style for the cells
 */
const cellStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'flex-start',
  marginRight: '0.5em',
};

export const TABLET_INDEX_COLUMNS: CustomColumnDefintion[] = [
  {
    column: 'imei',
    headerName: i18n.t('imei'),
    cellLink: 'ADD_TABLET',
    headerStyle: cellStyle,
    cellStyle,
  },
  {
    column: 'status',
    headerName: i18n.t('status'),
    headerStyle: cellStyle,
    cellStyle,
  },
  {
    column: 'dateAssociated',
    headerName: i18n.t('assocDate'),
    headerStyle: cellStyle,
    cellStyle,
  },
];
