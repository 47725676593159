import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { route } from 'react-router-typesafe-routes/dom'; // Or /native
import { AuthProvider } from './providers/AuthProvider';
import App from './App';
import Login from './pages/unauthorized/Login/Login';
import { RouterLoadingProvider } from './providers/RouterLoadingProvider';
import UnassignedUser from './pages/unauthorized/UnassignedUser/UnassignedUser';
import SensorManagement from './pages/authorized/SensorManagement/SensorManagement';
import Google from './pages/auth/Google';
import Microsoft from './pages/auth/Micosoft';
import NotFound from './pages/unauthorized/NotFound/NotFound';
import { CloudProvider } from './providers/CloudProvider';
import ProtectedRoute, { ROUTES_TYPE } from './routes/ProtectedRoute';
import { LoggingProvider } from './providers/LoggingProvider';
import AppRedirectMicrosoft from './pages/auth/AppRedirectMicrosoft';
import SiteManagement from './pages/authorized/SiteManagement/SiteManagement';
import TabletManagement from './pages/authorized/Tablet/TabletManagement/TabletManagement';
import Home from './pages/authorized/Home/Home';
import TabletOverview from './pages/authorized/Tablet/TabletOverview/TabletOverview';
import SetRoles from './pages/authorized/SetRoles/SetRoles';

const authorizedStack = (
  <RouterLoadingProvider>
    <LoggingProvider>
      <AuthProvider>
        <CloudProvider>
          <App />
        </CloudProvider>
      </AuthProvider>
    </LoggingProvider>
  </RouterLoadingProvider>
);

const unauthorizedStack = (
  <LoggingProvider>
    <AuthProvider>
      <Login />
    </AuthProvider>
  </LoggingProvider>
);

const googleStack = (
  <LoggingProvider>
    <AuthProvider>
      <Google />
    </AuthProvider>
  </LoggingProvider>
);

const microsoftStack = (
  <LoggingProvider>
    <AuthProvider>
      <Microsoft />
    </AuthProvider>
  </LoggingProvider>
);

const microsoftAppStack = (
  <LoggingProvider>
    <AuthProvider>
      <AppRedirectMicrosoft />
    </AuthProvider>
  </LoggingProvider>
);

export const ROUTES = {
  HOME: route(''),
  UNASSIGNED_USER: route('unassignedUser'),
  LOGIN: route('login'),
  SET_ROLES: route('setRoles'),
  ADD_SENSOR: route('addSensor'),
  TABLET_INDEX: route('tablet'),
  ADD_TABLET: route('tablet/:imei'),
  ADD_SITE: route('addSite'),
  OAUTH_GOOGLE_REDIRECT: route(
    process.env.REACT_APP_GOOGLE_PATH ?? 'oauth/google/code',
  ),
  OAUTH_MICROSOFT_REDIRECT: route(
    process.env.REACT_APP_MICROSOFT_PATH ?? 'oauth/microsoft/code',
  ),
  OAUTH_MICROSOFT_APP_REDIRECT: route(
    process.env.REACT_APP_MICROSOFT_APP_PATH ?? 'oauth/app/microsoft',
  ),
};

// If the relative url changes it must also be updated in 'homepage' in the package.json to build correctly
export const PORTAL_RELATIVE_URL = '/portal';

const NO_ROLES_ROUTES = [
  {
    path: ROUTES.UNASSIGNED_USER.path,
    element: <UnassignedUser />,
  },
  // add more routes here for no role users only
];

const INTERNAL_ROUTES = [
  {
    path: ROUTES.ADD_SENSOR.path,
    element: <SensorManagement />,
  },
  {
    path: ROUTES.TABLET_INDEX.path,
    element: <TabletOverview />,
  },
  {
    path: ROUTES.ADD_TABLET.path,
    element: <TabletManagement />,
  },
  {
    path: ROUTES.ADD_SITE.path,
    element: <SiteManagement />,
  },
  // add more routes here for internal users
];

/**
 * Shared between operations manager and security officer
 */
const SHARED_ROUTES = [
  {
    path: '',
    element: <Home />,
  },
  {
    path: ROUTES.SET_ROLES.path,
    element: <SetRoles />,
  },
];

export const router = createBrowserRouter(
  [
    {
      path: ROUTES.HOME.path,
      element: authorizedStack,
      errorElement: <NotFound />,
      children: [
        {
          element: (
            <ProtectedRoute
              allowedRoles={[
                ...ROUTES_TYPE.internal,
                ...ROUTES_TYPE.securityOfficerOnly,
              ]}
            />
          ),
          children: SHARED_ROUTES,
        },
        {
          element: <ProtectedRoute allowedRoles={ROUTES_TYPE.noRoles} />,
          children: NO_ROLES_ROUTES,
        },
        {
          element: <ProtectedRoute allowedRoles={ROUTES_TYPE.internal} />,
          children: INTERNAL_ROUTES,
        },
      ],
    },
    {
      path: ROUTES.LOGIN.path,
      element: unauthorizedStack,
      errorElement: <NotFound />,
    },
    {
      path: ROUTES.OAUTH_GOOGLE_REDIRECT.path,
      element: googleStack,
      errorElement: <NotFound />,
    },
    {
      path: ROUTES.OAUTH_MICROSOFT_REDIRECT.path,
      element: microsoftStack,
      errorElement: <NotFound />,
    },
    {
      path: ROUTES.OAUTH_MICROSOFT_APP_REDIRECT.path,
      element: microsoftAppStack,
      errorElement: <NotFound />,
    },
  ],
  { basename: PORTAL_RELATIVE_URL },
);
