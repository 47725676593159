import { useMemo } from 'react';
import useErrorHandling from '../hooks/useErrorHandling';
import Parse from '../parse';
import BaseEntity from './interfaces/BaseEntity';
import { Site } from './Site';

/**

 */
export interface TabletAttributes extends BaseEntity {
  site: Site;
  IMEI: string;
  internalDeviceId: string;
  associatedAt: Date;
  activatedAt?: Date;
}

export const classname = 'Tablet';

export class Tablet extends Parse.Object<TabletAttributes> {
  constructor(attributes: TabletAttributes) {
    super(classname, attributes);
  }
}

Parse.Object.registerSubclass(classname, Tablet);

export function useTabletService() {
  const getTabletByIMEI = useErrorHandling(
    async (imei: string): Promise<Tablet> => {
      const tabletQuery = new Parse.Query(Tablet);
      tabletQuery.include('site');
      tabletQuery.equalTo('IMEI', imei);
      const tablet = await tabletQuery.first();
      if (!tablet) {
        throw new Error('Tablet not found');
      }
      return tablet;
    },
    [],
  );

  return useMemo(
    () => ({
      getTabletByIMEI,
    }),
    [getTabletByIMEI],
  );
}
