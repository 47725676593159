import { useCallback, useEffect } from 'react';
import { commonTableStyles } from '../../../components/templates/Table/tableUtil';
import { useTableColumns } from '../../../hooks/useTableColumns';
import { useCloudContext } from '../../../providers/CloudProvider';
import { useLoading } from '../../../providers/LoadingProvider';
import { useThemedComponent } from '../../../providers/ThemeProvider';
import { SortOrder, TabletEnrolledColumns } from '../../../types/tables';
import { TABLET_ENROLLED_COLUMNS } from './helpers';
import Table from '../../../components/templates/Table/Table';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../providers/AuthProvider';
import { ROUTES } from '../../../router';
import i18n from '../../../translations';

export default function Home() {
  const { styles } = useThemedComponent([commonTableStyles]);
  const { setLoading } = useLoading();
  const { cloudService } = useCloudContext();
  const { isSecurityOfficer } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isSecurityOfficer) {
      navigate(ROUTES.SET_ROLES.path);
    }
  }, [isSecurityOfficer, navigate]);
  const {
    columnHelper,
    tableData,
    setTableData,
    rowSelection,
    setRowSelection,
    sorting,
    setSorting,
  } = useTableColumns<TabletEnrolledColumns>({
    defaultSorting: [{ colKey: 'deployDate', index: 1, order: SortOrder.DESC }],
  });

  const dataFetcher = useCallback(async () => {
    try {
      setLoading(true);
      const tabletData = await cloudService.requestTabletEnrolledTable({
        sort: sorting,
      });
      setTableData(tabletData.data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [cloudService, setLoading, setTableData, sorting]);

  useEffect(() => {
    dataFetcher();
  }, [dataFetcher]);

  return (
    <div style={styles.pageContainer}>
      <div style={styles.pageHeader}>
        <h1 style={styles.h1}>{i18n.t('welcome')}</h1>
      </div>
      <div style={{ ...styles.rowContainer, ...styles.tableSectionContainer }}>
        <div style={{ ...styles.rowContainer, ...styles.tableTopRow }}>
          <h3 style={styles.h3}>{i18n.t('tabletsActive')}</h3>
        </div>
        <Table
          columnHelper={columnHelper}
          columns={TABLET_ENROLLED_COLUMNS}
          sorting={sorting}
          setSorting={setSorting}
          data={tableData}
          selectable={true}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          tableLayout="fixed"
        />
      </div>
    </div>
  );
}
