import { LEGSysSensor } from '../../../schemas/LEGSysSensor';
import i18n from '../../../translations';
import { CustomColumnDefintion, SensorColumns } from '../../../types/tables';

/**
 * The columns definition for the set user roles page.
 * This is the order of the columns in the table and any customizations
 */
export const ROLE_COLUMNS: CustomColumnDefintion[] = [
  { column: 'mac', headerName: i18n.t('mac') },
  { column: 'dateAdded', headerName: i18n.t('dateAdded') },
];

export const formatSensors = (activeSensors: LEGSysSensor[]) => {
  if (activeSensors) {
    const sensorRows: SensorColumns[] = activeSensors.map(s => ({
      id: s.id,
      mac: s.attributes.mac,
      dateAdded: s.createdAt,
    }));
    return sensorRows;
  }
};
