import React, { useCallback, useState } from 'react';
import Popup, { PopupProps } from '../components/atoms/Popup/Popup';
import i18n from '../translations';
import { useThemedComponent, StyleSheet } from '../providers/ThemeProvider';

const usePopup = () => {
  const [visible, setVisible] = React.useState(false);
  const [props, setProps] = useState<PopupProps>();
  const { styles } = useThemedComponent([PopupStyles]);

  const hidePopup = useCallback(() => {
    setVisible(false);
    if (props) {
      setProps(prev => ({
        ...prev!,
        open: false,
      }));
    }
  }, [props]);

  const showPopup = useCallback(
    (props: Omit<PopupProps, 'open' | 'onClose'>) => {
      setVisible(true);
      const activeProps: PopupProps = {
        ...props,
        open: true,
        onClose: hidePopup,
      };
      setProps(activeProps);
    },
    [hidePopup],
  );

  const showGenericErrorPopup = useCallback(() => {
    showPopup({
      title: i18n.t('error'),
      buttons: [
        {
          title: i18n.t('dismiss'),
          style: { ...styles.primaryButton, ...styles.popupButton },
          onClick: () => {
            hidePopup();
          },
        },
      ],
      children: <p style={styles.errorContainer}>{i18n.t('pleaseTryAgain')}</p>,
      style: styles.popupButton,
    });
  }, [
    hidePopup,
    showPopup,
    styles.popupButton,
    styles.errorContainer,
    styles.primaryButton,
  ]);

  const renderPopup = useCallback(
    (popupProps?: PopupProps) => {
      const value: PopupProps =
        popupProps !== undefined ? { ...popupProps } : { ...props! };
      return visible ? <Popup {...value} /> : null;
    },
    [visible, props],
  );

  return { showPopup, hidePopup, renderPopup, showGenericErrorPopup };
};

export default usePopup;

const PopupStyles = (): StyleSheet => ({
  errorContainer: {
    marginTop: '3%',
    marginBottom: '3%',
    textAlign: 'center',
    lineHeight: '1.4rem',
  },
  popupButton: {
    maxWidth: '40%',
  },
});
