/* eslint-disable @typescript-eslint/no-explicit-any */
import { type ROUTES } from '../router';
import { type UnionToIntersection } from '../utils/type';
import { ISOString } from '../utils/dateTime';

/**
 * All tables in the database.
 * Remember to add any new table interfaces here!
 */
export type CustomTableColumns = {
  /**
   * This optional field is used for row identifier in row selection manipulations.
   * It will be used in getRowId if we want to key rowSelection state by something else
   * (most likely Parse object id) instead of the default indices.
   */
  id?: string;
} & (
  | UserRolesColumns
  | SensorColumns
  | TabletIndexColumns
  | TabletEnrolledColumns
);

/**
 * All keys of the tables in the database. Useful for making definitions.
 */
export type CustomTableColumnKeys = UnionToIntersection<CustomTableColumns>;

export type TableLink<T> = {
  params: object;
  value: T;
};

export interface SensorColumns {
  mac: string;
  dateAdded: Date;
}

export interface UserRolesColumns {
  user: string;
  dateAdded: Date;
  role: string;
}

export enum AccessType {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}

export enum TabletStatus {
  ACTIVE,
  INACTIVE,
}

export interface TabletIndexColumns {
  imei: string;
  status: TabletStatus;
  dateAssociated: Date;
}

export interface TabletEnrolledColumns {
  siteId: string;
  deviceId: string;
  imei: string;
  mac: string;
  deployDate: Date;
}

/**
 * The definition of a column in a table.
 */
export interface CustomColumnDefintion {
  column: keyof CustomTableColumnKeys; // the key of the column matching the type
  sortable?: boolean; // wether or not the column is sortable, defaults to true
  headerName: string; // the name of the column
  headerHelperText?: string; // the helper text for the column
  headerStyle?: React.CSSProperties; // the style of the header
  cellLink?: keyof typeof ROUTES; // the route (with out any parameters) to the page
  cellTransform?: (data: any) => any; // a function to transform the data
  cellTextColor?: (data: any) => string; // a function to color the data
  cellStyle?: React.CSSProperties; // the style of each cell in the column
  exportTransform?: (data: any) => any; // a function to transform the data for export
  includeInExport?: boolean; // whether or not to include the column in the export, defaults to true
  cellParams?: any; // any additional parameters to pass to the cell for links
}

export enum SortOrder {
  ASC,
  DESC,
}

export interface SortedCol {
  colKey: keyof CustomTableColumnKeys; // the key of the column matching the type;
  index: number; // the index of the column in the table
  order: SortOrder;
}

export interface TableOptions {
  /**
   * The columns that are sorted and their order
   */
  sort: SortedCol[];
  /**
   * The search string to filter the data
   */
  search?: string;
  /**
   * The filters to apply to the data
   */
  filters?: ColumnFilter[];
}

export enum FilterType {
  NUMBER,
  STRING,
  DATE,
  ARRAY_NUMBER,
  ARRAY_STRING,
  ARRAY_DATE,
  POINTER,
}

/**
 * Possible filters for a single column
 */
export interface ColumnFilter {
  columnName: string;
  filterType: FilterType;
  equalTo?: string;
  notEqualTo?: string;
  greaterThan?: string;
  lessThan?: string;
  greaterThanOrEqualTo?: string;
  lessThanOrEqualTo?: string;
  contains?: string;
  regex?: string;
}

export interface DateRangeOption {
  asString: string;
  startDate: Date;
  endDate: Date;
}

export interface TableResponse<T> extends TableOptions {
  data: T[];
  totalEntries: number;
}

export interface TableRequest extends TableOptions {}

export interface SingleSubjectRequest extends TableRequest {
  subjectId: string;
}

export interface DateRangePageRequest extends TableRequest {
  startDateISO: ISOString;
  endDateISO: ISOString;
}

export interface SingleDateRequest extends TableRequest {
  dateISO: ISOString;
}

export interface UserRolesRequest extends TableRequest {
  type: AccessType;
}
export interface TabletIndexRequest extends TableRequest {}
