import { type StyleSheet } from '../../../providers/ThemeProvider';

export default (): StyleSheet => ({
  btnGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: '1%',
    flex: 1,
  },
});
