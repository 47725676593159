import { LEGSysSensor } from './LEGSysSensor';
import Parse from '../parse';
import BaseEntity from './interfaces/BaseEntity';
import { Tablet } from './Tablet';
import useErrorHandling from '../hooks/useErrorHandling';
import { useMemo } from 'react';
export interface SensorEventAttributes extends BaseEntity {
  sensor: LEGSysSensor;
  tablet: Tablet;
  type: SensorEventTypeBluetooth | SensorEventTypeHistory;
  category: SensorEventCategory;
}

enum SensorEventTypeBluetooth {
  CONNECTED,
  DISCONNECTED,
  START_STREAMING,
  STOP_STREAMING,
  REQUEST_CONNECT,
  CONNECT_FAILED,
  STREAM_FAILED,
  TRY_CALIBRATE,
  CALIBRATION_SUCCESS,
  CALIBRATION_FAILURE,
}

export enum SensorEventTypeHistory {
  PENDING = 'PENDING',
  PAIRED = 'PAIRED',
  UNPAIRED = 'UNPAIRED',
}

export enum SensorEventCategory {
  HISTORY = 'HISTORY',
  BLUETOOTH = 'BLUETOOTH',
}

export const classname = 'SensorEvent';

export class SensorEvent extends Parse.Object<SensorEventAttributes> {
  constructor(attributes: SensorEventAttributes) {
    super(classname, attributes);
  }
}

Parse.Object.registerSubclass(classname, SensorEvent);

export function useSensorEventService() {
  const getSensorAssociatedWithTablet = useErrorHandling(
    async (tablet: Tablet): Promise<LEGSysSensor | undefined> => {
      const sensorEventQuery = new Parse.Query(SensorEvent);
      sensorEventQuery.include('sensor');
      sensorEventQuery.equalTo('category', SensorEventCategory.HISTORY);
      sensorEventQuery.equalTo('tablet', tablet);
      sensorEventQuery.notEqualTo('type', SensorEventTypeHistory.UNPAIRED);
      sensorEventQuery.descending('createdAt');

      const event = await sensorEventQuery.first();
      return event?.get('sensor');
    },
    [],
  );

  return useMemo(
    () => ({
      getSensorAssociatedWithTablet,
    }),
    [getSensorAssociatedWithTablet],
  );
}
