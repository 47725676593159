import React, { useEffect, useCallback } from 'react';
import Table from '../../../components/templates/Table/Table';
import { useLoading } from '../../../providers/LoadingProvider';
import { useThemedComponent } from '../../../providers/ThemeProvider';
import {
  type CustomTableColumns,
  SortOrder,
  UserRolesColumns,
  AccessType,
} from '../../../types/tables';
import { ButtonRow } from '../../../components/atoms/ButtonRow/ButtonRow';
import i18n from '../../../translations';
import { useCloudContext } from '../../../providers/CloudProvider';
import {
  ExternalRoles,
  InternalRoles,
  RoleOperation,
} from '../../../types/cloud';
import { useTableColumns } from '../../../hooks/useTableColumns';
import { commonTableStyles } from '../../../components/templates/Table/tableUtil';
import SetRolesStyles from './SetRolesStyles';
import { useAuth } from '../../../providers/AuthProvider';
import { EXTERNAL_ROLES_COLUMNS, INTERNAL_ROLES_COLUMNS } from './helpers';

/**
 * Should only be visible to security officer
 * @returns
 */
export default function SetRoles() {
  const { styles } = useThemedComponent([commonTableStyles, SetRolesStyles]);
  const { setLoading } = useLoading();
  const { cloudService } = useCloudContext();
  const { isSecurityOfficer: isInternal } = useAuth();
  const {
    columnHelper,
    tableData,
    setTableData,
    rowSelection,
    setRowSelection,
    sorting,
    setSorting,
  } = useTableColumns<UserRolesColumns>({
    defaultSorting: [{ colKey: 'user', index: 0, order: SortOrder.ASC }],
    tableName: isInternal ? i18n.t('setInternal') : i18n.t('setExternal'),
  });

  /**
   * Fetch data for table
   */
  const dataFetcher = useCallback(async () => {
    setLoading(true);
    try {
      const tableRes = await cloudService.requestUserRolesTable({
        sort: sorting,
        type: isInternal ? AccessType.INTERNAL : AccessType.EXTERNAL,
      });
      setTableData(tableRes.data);
    } catch (e) {
      alert(`Failed to get non-subject users ${e}`);
    } finally {
      setLoading(false);
    }
  }, [setLoading, cloudService, sorting, isInternal, setTableData]);

  useEffect(() => {
    dataFetcher();
  }, [dataFetcher]);

  /**
   * Grant or Revoke Internal Roles on selected users
   */
  const onSelectRoles = useCallback(
    async (operation: RoleOperation) => {
      if (Object.keys(rowSelection).length === 0) {
        return;
      }
      await Promise.all(
        Object.keys(rowSelection).map(userId => {
          return cloudService.grantOrRevokeRoles({
            userId,
            operation,
            roles: isInternal ? InternalRoles : ExternalRoles,
            internal: isInternal,
          });
        }),
      );
      await dataFetcher();
      setRowSelection({});
    },
    [rowSelection, dataFetcher, setRowSelection, cloudService, isInternal],
  );

  /**
   * Helper to get row id for row selection
   */
  const getRowId = (row: CustomTableColumns, index: number): string => {
    return row.id ?? index.toString();
  };

  return (
    <div style={styles.pageContainer}>
      <div style={styles.pageHeader}>
        <h1 style={styles.h1}>
          {isInternal ? i18n.t('setInternal') : i18n.t('setExternal')}
        </h1>
        <ButtonRow
          buttons={[
            {
              title: isInternal ? i18n.t('setCOM') : i18n.t('setSiteStaff'),
              onClick: () => onSelectRoles(RoleOperation.ADD),
              style: { ...styles.pageHeaderButton },
            },
            {
              title: isInternal
                ? i18n.t('removeCOM')
                : i18n.t('removeSiteStaff'),
              onClick: () => onSelectRoles(RoleOperation.REMOVE),
              style: { ...styles.pageHeaderButton },
            },
          ]}
          style={styles.btnGroup}
        />
      </div>
      <Table
        columnHelper={columnHelper}
        columns={isInternal ? INTERNAL_ROLES_COLUMNS : EXTERNAL_ROLES_COLUMNS}
        sorting={sorting}
        setSorting={setSorting}
        data={tableData}
        selectable={true}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        getRowId={getRowId}
      />
    </div>
  );
}
