import { useCallback, useEffect } from 'react';
import { commonTableStyles } from '../../../../components/templates/Table/tableUtil';
import { useTableColumns } from '../../../../hooks/useTableColumns';
import { useCloudContext } from '../../../../providers/CloudProvider';
import { useLoading } from '../../../../providers/LoadingProvider';
import { useThemedComponent } from '../../../../providers/ThemeProvider';
import { SortOrder, TabletIndexColumns } from '../../../../types/tables';
import Table from '../../../../components/templates/Table/Table';
import { TABLET_INDEX_COLUMNS } from '../helpers';
import i18n from '../../../../translations';

export default function TabletOverview() {
  const { styles } = useThemedComponent([commonTableStyles]);
  const { setLoading } = useLoading();
  const { cloudService } = useCloudContext();

  const {
    columnHelper,
    tableData,
    setTableData,
    rowSelection,
    setRowSelection,
    sorting,
    setSorting,
  } = useTableColumns<TabletIndexColumns>({
    defaultSorting: [
      { colKey: 'dateAssociated', index: 1, order: SortOrder.ASC },
    ],
  });

  const dataFetcher = useCallback(async () => {
    try {
      setLoading(true);
      const tabletData = await cloudService.requestTabletIndexTable({
        sort: sorting,
      });
      setTableData(tabletData.data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [cloudService, setLoading, setTableData, sorting]);

  useEffect(() => {
    dataFetcher();
  }, [dataFetcher]);

  return (
    <div style={styles.pageContainer}>
      <div style={styles.pageHeader}>
        <h1 style={styles.h1}>{i18n.t('tablets')}</h1>
      </div>
      <div style={{ ...styles.rowContainer, ...styles.tableSectionContainer }}>
        <div style={{ ...styles.rowContainer, ...styles.tableTopRow }}>
          <h3 style={styles.h3}>{i18n.t('tabletsAssociated')}</h3>
        </div>
        <Table
          columnHelper={columnHelper}
          columns={TABLET_INDEX_COLUMNS}
          sorting={sorting}
          setSorting={setSorting}
          data={tableData}
          selectable={true}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
        />
      </div>
    </div>
  );
}
