import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import PopupStyles from './PopupStyles';
import { useThemedComponent } from '../../../providers/ThemeProvider';
import { ButtonProps, ButtonRow } from '../ButtonRow/ButtonRow';
import { XMarkIcon } from '@heroicons/react/20/solid';

export interface PopupProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  buttons?: ButtonProps[];
  xIcon?: boolean;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

export default function Popup({
  open,
  onClose,
  title,
  buttons,
  xIcon,
  ...props
}: PopupProps) {
  const cancelButtonRef = useRef(null);
  const { styles } = useThemedComponent([PopupStyles]);

  // Default to true
  const showXIcon = xIcon !== undefined ? xIcon : true;

  /**
   * Based of https://tailwindui.com/components/application-ui/overlays/dialogs
   */
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        style={styles.dialogContainer}
        initialFocus={cancelButtonRef}
        onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div style={styles.blurContainer} />
        </Transition.Child>

        <div style={styles.dialogOuterContainer}>
          <div style={styles.dialogInnerContainer}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel style={styles.panel}>
                <div style={styles.panelDiv}>
                  {showXIcon && (
                    <XMarkIcon
                      width={30}
                      height={30}
                      style={styles.xmark}
                      onClick={onClose}
                    />
                  )}
                  <Dialog.Title style={styles.h1}>{title}</Dialog.Title>
                  <div style={styles.childrenContainer}>{props.children}</div>
                  {buttons && (
                    <ButtonRow buttons={buttons} style={styles.btn} />
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
