import React, { SyntheticEvent, useCallback, useEffect } from 'react';
import { FormInput } from '../../../components/atoms/FormInput/FormInput';
import { Form, Formik, FormikHelpers } from 'formik';
import { ROLE_COLUMNS, formatSensors } from './helpers';
import i18n from '../../../translations';
import * as Yup from 'yup';
import { ButtonRow } from '../../../components/atoms/ButtonRow/ButtonRow';
import { useThemedComponent } from '../../../providers/ThemeProvider';
import SensorManagementStyles from './SensorManagementStyles';
import Table from '../../../components/templates/Table/Table';
import { SensorColumns, SortOrder } from '../../../types/tables';
import { commonTableStyles } from '../../../components/templates/Table/tableUtil';
import { useTableColumns } from '../../../hooks/useTableColumns';
import { useLoading } from '../../../providers/LoadingProvider';
import { useCloudContext } from '../../../providers/CloudProvider';
import usePopup from '../../../hooks/usePopup';

export interface AddSensorFields {
  mac: string;
}

const initialValues: AddSensorFields = {
  mac: '',
};

export enum SensorOperation {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
}

// modify error messages in the translation json file
const addSensorSchema = Yup.object<AddSensorFields>().shape({
  mac: Yup.string()
    .trim()
    .matches(
      new RegExp('^([0-9a-fA-F][0-9a-fA-F]:){5}([0-9a-fA-F][0-9a-fA-F])$'),
    )
    .required(''),
});

export default function SensorManagement() {
  const { styles } = useThemedComponent([
    commonTableStyles,
    SensorManagementStyles,
  ]);
  const { setLoading } = useLoading();
  const { cloudService } = useCloudContext();
  const { showPopup, hidePopup, renderPopup, showGenericErrorPopup } =
    usePopup();
  const {
    columnHelper,
    tableData,
    setTableData,
    rowSelection,
    setRowSelection,
    sorting,
    setSorting,
  } = useTableColumns<SensorColumns>({
    defaultSorting: [{ colKey: 'dateAdded', index: 1, order: SortOrder.ASC }],
    tableName: i18n.t('currentSensors'),
  });

  const dataFetcher = useCallback(async () => {
    try {
      setLoading(true);
      const activeSensors = await cloudService.getActiveSensors();
      const sensors = formatSensors(activeSensors);
      if (sensors) {
        setTableData(sensors);
      }
    } catch (e) {
      alert('Failed to get sensors: ' + JSON.stringify(e));
    } finally {
      setLoading(false);
    }
  }, [cloudService, setLoading, setTableData]);

  useEffect(() => {
    dataFetcher();
  }, [dataFetcher]);

  const onSubmit = useCallback(
    async (
      { mac }: AddSensorFields,
      action: FormikHelpers<AddSensorFields>,
    ) => {
      try {
        setLoading(true);
        const { failures } = await cloudService.addOrRemoveSensor({
          sensors: [{ mac }],
          operation: SensorOperation.ADD,
        });
        document.getElementsByName('mac')[0].focus();
        await dataFetcher();
        if (failures.some(sensorAttrs => sensorAttrs.mac === mac)) {
          showGenericErrorPopup();
        } else {
          action.resetForm();
        }
      } catch (e) {
        console.error(`Failed to add sensor ${JSON.stringify(e)}`);
        showGenericErrorPopup();
      } finally {
        setLoading(false);
      }
    },
    [cloudService, dataFetcher, setLoading, showGenericErrorPopup],
  );

  const removeSensors = useCallback(
    async (e: SyntheticEvent) => {
      try {
        e.stopPropagation();
        if (Object.keys(rowSelection).length === 0) {
          return;
        }
        showPopup({
          title: i18n.t('areYouSure'),
          buttons: [
            {
              title: i18n.t('dismiss'),
              style: { ...styles.primaryButton, ...styles.popupButton },
              onClick: () => {
                setRowSelection({});
                hidePopup();
              },
            },
            {
              title: i18n.t('confirm'),
              style: { ...styles.primaryButton, ...styles.popupButton },
              onClick: async () => {
                // confirm, now we can remove sensor
                const selectedSensors = Object.keys(rowSelection).map(
                  (index: string) => ({
                    // selection key is the row index
                    mac: (tableData.at(+index) as SensorColumns)?.mac,
                  }),
                );
                await cloudService.addOrRemoveSensor({
                  sensors: selectedSensors,
                  operation: SensorOperation.REMOVE,
                });
                await dataFetcher();
                setRowSelection({});
                hidePopup();
              },
            },
          ],
          children: (
            <p style={styles.popupContainer}>{i18n.t('sureRemoveSensor')}</p>
          ),
          style: styles.popupButton,
        });
      } catch (e) {
        console.error('Error when removing sensors' + JSON.stringify(e));
        showGenericErrorPopup();
      }
    },
    [
      cloudService,
      dataFetcher,
      hidePopup,
      rowSelection,
      setRowSelection,
      showGenericErrorPopup,
      showPopup,
      styles.popupButton,
      styles.popupContainer,
      styles.primaryButton,
      tableData,
    ],
  );

  return (
    <div style={{ ...styles.pageContainer, ...styles.container }}>
      <div style={styles.addSensorContainer}>
        <div style={styles.pageHeader}>
          <h1 style={styles.h1}>{i18n.t('sensorManagement')}</h1>
        </div>
        <div style={styles.formContainer}>
          <Formik
            validationSchema={addSensorSchema}
            initialValues={initialValues}
            onSubmit={onSubmit}>
            {({ handleSubmit, resetForm }) => (
              <Form onSubmit={handleSubmit} style={styles.betweenInput}>
                <FormInput
                  type="text"
                  name={'mac'}
                  label={i18n.t('sensor')}
                  placeholder={i18n.t('scanSensor')}
                  autoFocus
                  transformInput={event => {
                    if (event.currentTarget.value.length === 17) {
                      event.currentTarget.blur();
                    }
                    return event;
                  }}
                />
                <ButtonRow
                  buttons={[
                    {
                      title: i18n.t('cancel'),
                      type: 'button',
                      style: styles.cancelButton,
                      onClick: () => {
                        resetForm();
                      },
                    },
                    {
                      title: i18n.t('save'),
                      type: 'button',
                      style: styles.saveButton,
                      onClick: () => handleSubmit(),
                    },
                  ]}
                  style={styles.buttonContainer}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div
        style={{
          ...styles.tableSectionContainer,
          ...styles.currentSensorsContainer,
        }}>
        <div style={styles.pageHeader}>
          <h1 style={styles.h1}>{i18n.t('currentSensors')}</h1>
          <div style={styles.btnGroup}>
            <button style={styles.removeBtn} onClick={removeSensors}>
              {i18n.t('remove')}
            </button>
          </div>
        </div>
        <Table
          columnHelper={columnHelper}
          columns={ROLE_COLUMNS}
          sorting={sorting}
          setSorting={setSorting}
          data={tableData}
          selectable={true}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
        />
      </div>
      {renderPopup()}
    </div>
  );
}
