import { Outlet, useOutletContext } from 'react-router-dom';
import { useAuth } from '../providers/AuthProvider';
import { ExternalRoles, InternalRoles, TeleFootXRoles } from '../types/cloud';
import { useEffect, useMemo } from 'react';
import { useLoading } from '../providers/LoadingProvider';
import NotFound from '../pages/unauthorized/NotFound/NotFound';
import UnassignedUser from '../pages/unauthorized/UnassignedUser/UnassignedUser';

export const ROUTES_TYPE = {
  // only no role users should be able to access unassigned page
  noRoles: [],
  // authorized people
  internal: InternalRoles,
  external: ExternalRoles,
  // security officer only
  securityOfficerOnly: [TeleFootXRoles.SecurityOfficer],
} as const;

interface ProtectedRouteProps {
  allowedRoles: readonly TeleFootXRoles[];
}

const ProtectedRoute = ({ allowedRoles }: ProtectedRouteProps) => {
  const { roles } = useAuth();
  const { setLoading } = useLoading();
  const context = useOutletContext();

  useEffect(() => {
    if (roles === undefined) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [roles, setLoading]);

  const routeWithRoles = useMemo(() => {
    if (roles === undefined) {
      return;
    }

    if (roles.length === 0) {
      return <UnassignedUser />;
    }

    if (allowedRoles.every(role => !roles.includes(role))) {
      return <NotFound />;
    }

    return <Outlet context={context} />;
  }, [allowedRoles, context, roles]);

  return <>{routeWithRoles}</>;
};

export default ProtectedRoute;
