import { type StyleSheet } from '../../../providers/ThemeProvider';

export default (): StyleSheet => ({
  popupContainer: {
    marginTop: '5%',
    marginBottom: '5%',
  },
  popupButton: {
    maxWidth: '40%',
  },
});
