import { Formik, FormikValues } from 'formik';
import { Form, useNavigate, useParams } from 'react-router-dom';
import { ButtonRow } from '../../../../components/atoms/ButtonRow/ButtonRow';
import {
  FormDropdown,
  FormDropdownOption,
} from '../../../../components/atoms/FormDropdown/FormDropdown';
import { FormInput } from '../../../../components/atoms/FormInput/FormInput';
import { useThemedComponent } from '../../../../providers/ThemeProvider';
import i18n from '../../../../translations';
import { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import usePopup from '../../../../hooks/usePopup';
import { commonFormStyles } from '../../../../components/templates/Form/formUtil';
import { useCloudContext } from '../../../../providers/CloudProvider';
import AddTabletStyles from './TabletManagementStyles';
import { useLoading } from '../../../../providers/LoadingProvider';

export interface TabletFormFields {
  deviceId: string;
  siteId: string;
  imei: string;
  sensor: string;
}

const TabletFormSchema = Yup.object<TabletFormFields>().shape({
  deviceId: Yup.string().required(''),
  siteId: Yup.string().required(''),
  sensor: Yup.string().required(''),
});

export default function TabletManagement() {
  const { styles } = useThemedComponent([commonFormStyles, AddTabletStyles]);
  const { imei } = useParams();
  const {
    tabletService,
    sensorEventService,
    siteService,
    sensorService,
    cloudService,
  } = useCloudContext();
  const [initialValues, setInitialValues] = useState<TabletFormFields>();
  const [isEdit, setIsEdit] = useState<boolean>();
  const navigate = useNavigate();
  const [siteOptions, setSiteOptions] =
    useState<FormDropdownOption<string>[]>();
  const [sensorOptions, setSensorOptions] =
    useState<FormDropdownOption<string>[]>();
  const { renderPopup, showPopup, hidePopup, showGenericErrorPopup } =
    usePopup();
  const { setLoading } = useLoading();
  const dataFetcher = useCallback(async () => {
    if (!imei) {
      return;
    }
    const tablet = await tabletService.getTabletByIMEI(imei);
    const [sensorAssociated, sites, sensors] = await Promise.all([
      sensorEventService.getSensorAssociatedWithTablet(tablet),
      siteService.getAllSites(),
      sensorService.getAvailableSensors(),
    ]);

    setSiteOptions(
      sites.map(s => ({
        label: s.get('name'),
        value: s.id,
      })),
    );

    setSensorOptions(
      (sensorAssociated ? [sensorAssociated, ...sensors] : sensors).map(s => ({
        label: s.get('mac'),
        value: s.id,
      })),
    );

    setInitialValues(prev => ({
      deviceId: tablet?.get('internalDeviceId') ?? prev?.deviceId ?? '',
      imei: tablet?.get('IMEI') ?? prev?.imei ?? '',
      siteId: tablet?.get('site')?.id ?? prev?.siteId ?? '',
      sensor: sensorAssociated?.id ?? prev?.sensor ?? '',
    }));

    setIsEdit(!!tablet?.get('activatedAt'));
  }, [imei, sensorEventService, sensorService, siteService, tabletService]);

  useEffect(() => {
    dataFetcher();
  }, [dataFetcher]);

  const onSubmit = useCallback(
    async ({ deviceId, sensor, siteId }: FormikValues) => {
      try {
        setLoading(true);
        await cloudService.addEditTablet({
          tabletIMEI: imei!,
          deviceId,
          sensorId: sensor,
          siteId,
        });
        showPopup({
          title: i18n.t('success'),
          buttons: [
            {
              title: i18n.t('dismiss'),
              style: { ...styles.primaryButton, ...styles.popupButton },
              onClick: () => {
                hidePopup();
                navigate(-1); // home or tablet index page
              },
            },
          ],
          children: (
            <p style={styles.popupContainer}>
              {isEdit ? i18n.t('tabletEdited') : i18n.t('tabletCreated')}
            </p>
          ),
          style: styles.popupButton,
        });
      } catch (e) {
        console.error(JSON.stringify(e));
        showGenericErrorPopup();
      } finally {
        setLoading(false);
      }
    },
    [
      cloudService,
      hidePopup,
      imei,
      isEdit,
      navigate,
      setLoading,
      showGenericErrorPopup,
      showPopup,
      styles.popupButton,
      styles.popupContainer,
      styles.primaryButton,
    ],
  );

  return !initialValues ? null : (
    <div style={styles.pageContainer}>
      <div style={styles.pageHeader}>
        <h1 style={styles.h1}>
          {isEdit ? i18n.t('editTablet') : i18n.t('addTablet')}
        </h1>
      </div>
      <div style={styles.formContainer}>
        <Formik
          initialValues={initialValues}
          validationSchema={TabletFormSchema}
          onSubmit={onSubmit}>
          {({ isSubmitting, handleSubmit, resetForm }) => (
            <Form style={styles.betweenInput} onSubmit={handleSubmit}>
              <FormInput
                type="text"
                name="deviceId"
                label={i18n.t('deviceID')}
                placeholder={i18n.t('enterDeviceID')}
              />
              <FormDropdown
                name="siteId"
                options={siteOptions ?? []}
                label={i18n.t('siteID')}
                placeholder={i18n.t('selectSite')}
              />
              <FormInput
                type="text"
                name="imei"
                label={i18n.t('imei')}
                disabled={true}
              />
              <FormDropdown
                name="sensor"
                options={sensorOptions ?? []}
                label={i18n.t('sensor')}
                placeholder={i18n.t('selectMac')}
              />
              <ButtonRow
                buttons={[
                  {
                    title: i18n.t('cancel'),
                    type: 'button',
                    disabled: isSubmitting,
                    style: styles.secondaryButton,
                    onClick: () => {
                      resetForm();
                      navigate(-1);
                    },
                  },
                  {
                    title: i18n.t('save'),
                    type: 'submit',
                    disabled: isSubmitting,
                    style: styles.primaryButton,
                  },
                ]}
                style={styles.buttonContainer}
              />
            </Form>
          )}
        </Formik>
      </div>
      {renderPopup()}
    </div>
  );
}
