import {
  type ThemeContent,
  type StyleSheet,
} from '../../../providers/ThemeProvider';

export default (theme: ThemeContent): StyleSheet => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: '3%',
  },
  addSensorContainer: {
    flex: 1,
  },
  currentSensorsContainer: {
    flex: 2,
  },
  formContainer: {
    width: '100%',
  },
  extraRowSpacing: {
    marginBottom: `calc(15px + 0.5em)`,
  },
  betweenInput: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  removeBtn: {
    borderStyle: 'none',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.875rem',
    fontWeight: '500',
    cursor: 'pointer',
    padding: '0.375rem 0.75rem',
    border: 'none',
    borderRadius: '0.375rem',
    backgroundColor: theme.colors.primary,
    color: theme.colors.white,
    marginLeft: '0.25rem',
    marginRight: '0.25rem',
  },
  exportBtn: {
    backgroundColor: theme.colors.transparentWhite,
    borderRadius: 5,
    height: '100%',
    borderStyle: 'solid',
  },
  underTitleContainer: {
    marginTop: 20,
  },
  buttonContainer: {
    display: 'flex',
    gap: 10,
    marginTop: 20,
    width: '100%',
  },
  cancelButton: {
    backgroundColor: theme.colors.transparentWhite,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: theme.colors.primary,
    width: 250,
    height: 40,
    borderStyle: 'solid',
  },
  saveButton: {
    backgroundColor: theme.colors.primary,
    color: theme.colors.white,
    borderRadius: 5,
    border: 'none',
    width: 250,
    height: 40,
    borderStyle: 'solid',
  },
  popupContainer: {
    marginTop: '3%',
    marginBottom: '3%',
    textAlign: 'center',
    lineHeight: '1.4rem',
  },
  popupButton: {
    maxWidth: '40%',
  },
});
