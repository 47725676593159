import {
  type StyleSheet,
  useThemedComponent,
  ThemeContent,
} from '../../../providers/ThemeProvider';
import i18n from '../../../translations';

export default function UnassignedUser() {
  const { styles } = useThemedComponent([UnassignedUserStyles]);
  return (
    <div style={{ ...styles.pageContainer, ...styles.container }}>
      <h1 style={styles.h1}>{i18n.t('welcome')}</h1>
      <hr style={styles.divider} />
      <label>{i18n.t('pleaseRequestPortalAccess')}</label>
    </div>
  );
}

const UnassignedUserStyles = (theme: ThemeContent): StyleSheet => ({
  container: {
    gap: 10,
  },
  divider: {
    borderTopWidth: '1px',
    borderTopColor: theme.colors.coolGrey,
    width: '100%',
  },
});
