import React from 'react';
import { Form, Formik, FormikValues } from 'formik';
import { useThemedComponent } from '../../../providers/ThemeProvider';
import { useCallback, useEffect } from 'react';
import { FormInput } from '../../../components/atoms/FormInput/FormInput';
import { ButtonRow } from '../../../components/atoms/ButtonRow/ButtonRow';
import i18n from '../../../translations';
import SiteManagementStyles from './SiteManagementStyles';
import { useCloudContext } from '../../../providers/CloudProvider';
import { useLoading } from '../../../providers/LoadingProvider';
import usePopup from '../../../hooks/usePopup';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../router';
import { commonFormStyles } from '../../../components/templates/Form/formUtil';

const initialValues = {
  siteId: '',
};

export default function SiteManagement() {
  const { styles } = useThemedComponent([
    commonFormStyles,
    SiteManagementStyles,
  ]);
  const { cloudService } = useCloudContext();
  const { setLoading } = useLoading();
  const { showPopup, hidePopup, renderPopup, showGenericErrorPopup } =
    usePopup();
  const navigate = useNavigate();

  const onSubmit = useCallback(
    async ({ siteId }: FormikValues) => {
      try {
        setLoading(true);
        const newSite = await cloudService.addSite({ siteId });
        showPopup({
          title: i18n.t('success'),
          buttons: [
            {
              title: i18n.t('dismiss'),
              style: { ...styles.primaryButton, ...styles.popupButton },
              onClick: () => {
                hidePopup();
                navigate(ROUTES.HOME.path);
              },
            },
          ],
          children: (
            <p style={styles.popupContainer}>
              {i18n.t('siteSaved', { siteId: newSite.get('name') })}
            </p>
          ),
          style: styles.popupButton,
        });
      } catch (e) {
        console.error(JSON.stringify(e));
        showGenericErrorPopup();
      } finally {
        setLoading(false);
      }
    },
    [
      cloudService,
      hidePopup,
      navigate,
      setLoading,
      showGenericErrorPopup,
      showPopup,
      styles.popupButton,
      styles.popupContainer,
      styles.primaryButton,
    ],
  );

  useEffect(() => {}, [
    showPopup,
    hidePopup,
    styles.popupContainer,
    styles,
    navigate,
  ]);
  return (
    <div style={styles.pageContainer}>
      <div style={styles.pageHeader}>
        <h1 style={styles.h1}>{i18n.t('siteManagement')}</h1>
      </div>
      <div style={styles.formContainer}>
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ handleSubmit, resetForm }) => (
            <Form onSubmit={handleSubmit}>
              <FormInput
                type={'text'}
                name={'siteId'}
                label={i18n.t('siteID')}
                placeholder={i18n.t('enterSite')}
              />
              <ButtonRow
                buttons={[
                  {
                    title: i18n.t('cancel'),
                    type: 'button',
                    style: styles.secondaryButton,
                    onClick: () => {
                      resetForm();
                      navigate(ROUTES.HOME.path);
                    },
                  },
                  {
                    title: i18n.t('save'),
                    type: 'button',
                    style: styles.primaryButton,
                    onClick: () => handleSubmit(),
                  },
                ]}
                style={styles.buttonContainer}
              />
            </Form>
          )}
        </Formik>
      </div>
      {renderPopup()}
    </div>
  );
}
