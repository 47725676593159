import i18n from '../../../translations';
import { CustomColumnDefintion } from '../../../types/tables';

/**
 * The style for the cells
 */
const cellStyle: React.CSSProperties = {
  display: 'flex',
  marginRight: '0.5em',
};

export const TABLET_ENROLLED_COLUMNS: CustomColumnDefintion[] = [
  {
    column: 'siteId',
    headerName: i18n.t('siteID'),
    headerStyle: cellStyle,
    cellStyle,
  },
  {
    column: 'deviceId',
    headerName: i18n.t('deviceID'),
    headerStyle: cellStyle,
    cellStyle,
  },
  {
    column: 'imei',
    headerName: i18n.t('imei'),
    cellLink: 'ADD_TABLET',
    headerStyle: cellStyle,
    cellStyle,
  },
  {
    column: 'mac',
    headerName: i18n.t('mac'),
    headerStyle: cellStyle,
    cellStyle,
  },
  {
    column: 'deployDate',
    headerName: i18n.t('deployDate'),
    headerStyle: cellStyle,
    cellStyle,
  },
];
