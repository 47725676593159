import { useEffect } from 'react';

export default function AppRedirectMicrosoft() {
  useEffect(() => {
    const url = new URL(window.location.href);
    const newCode = url.searchParams.get('code');
    if (newCode) {
      window.location.href = `telefootx://microsoft?code=${newCode}`;
      // close the window to prevent a redirect to the portal login page
      window.close();
    }
  }, []);

  return null;
}
