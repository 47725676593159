import Parse from '../parse';
import { SensorAttributes } from '../schemas/LEGSysSensor';

export enum TeleFootXRoles {
  // Site Staff (external role)
  SiteStaff = 'SiteStaff', // Can can create, edit, can update data related to other SiteStaff
  SiteStaffRead = 'SiteStaffRead', // A role to grant read access to SiteStaff objects
  SiteStaffWrite = 'SiteStaffWrite', // A role to grant write access to SiteStaff objects

  // Operations Manager
  OperationsManager = 'OperationsManager', // Can grant the SiteStaff role
  OperationsManagerRead = 'OperationsManagerRead', // A role to grant read access to Operations Manager objects
  OperationsManagerWrite = 'OperationsManagerWrite', // A role to grant write access to Operations Manager objects

  // Security Officer Manager (Internal role)
  SecurityOfficer = 'SecurityOfficer', // Can grant the SiteStaff role
  SecurityOfficerRead = 'SecurityOfficerRead', // A role to grant read access to Security Officer objects
  SecurityOfficerWrite = 'SecurityOfficerWrite', // A role to grant write access to Security Officer objects
}

export const ExternalRoles = [TeleFootXRoles.SiteStaff];
export const InternalRoles = [TeleFootXRoles.OperationsManager];

export enum RoleOperation {
  ADD = 'add',
  REMOVE = 'remove',
}

export type GetAllUsersResponse = {
  user: Parse.User;
  roles: TeleFootXRoles[];
}[];

export interface AddOrRemoveSensorResponse {
  successes: Partial<SensorAttributes>[];
  failures: Partial<SensorAttributes>[];
}

export enum Side {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}
