import {
  ColumnHelper,
  RowSelectionState,
  createColumnHelper,
} from '@tanstack/react-table';
import { ColumnFilter, CustomTableColumns, SortedCol } from '../types/tables';
import { useCallback, useState } from 'react';
import DropDownSelect from '../components/atoms/DropDownSelect/DropDownSelect';
import { getCsv } from '../utils/tables';
import { useLogging } from '../providers/LoggingProvider';

export interface UseTableColumnsProps {
  defaultSorting?: SortedCol[];
  defaultSearch?: string;
  defaultFilters?: ColumnFilter[];
  defaultRowSelection?: RowSelectionState;
  tableName?: string;
  transformExportData?: (data: CustomTableColumns[]) => unknown[];
}

/**
 * Helper for setting up all the states needed for a table
 * @param {UseTableColumnsProps} props
 * @returns {typeof this}
 */
export const useTableColumns = <T extends CustomTableColumns>(
  props?: UseTableColumnsProps,
) => {
  // create the column helper
  const columnHelper =
    createColumnHelper<T>() as unknown as ColumnHelper<CustomTableColumns>;

  // raw data from the api
  const [fetchedData, setFetchedData] = useState<unknown>([]);
  // stable data for the table (mutated fetched data)
  const [tableData, setTableData] = useState<CustomTableColumns[]>([]);
  // total entries in the table
  const [totalEntries, setTotalEntries] = useState<number>(0);
  // search string filter for the table
  const [search, setSearch] = useState<string>(props?.defaultSearch ?? '');
  // sorting for the table
  const [sorting, setSorting] = useState<SortedCol[]>(
    props?.defaultSorting ?? [],
  );
  // filters for the table
  const [filters, setFilters] = useState<ColumnFilter[]>(
    props?.defaultFilters ?? [],
  );
  // the row selection for the table
  const [rowSelection, setRowSelection] = useState(
    props?.defaultRowSelection ?? {},
  );
  // the date that the table data is generated
  const [lastSynced, setLastSynced] = useState<Date>();
  const [tableName, setTableName] = useState<string | undefined>(
    props?.tableName,
  );

  const exportOptions = ['csv', 'xlsx'];
  const logger = useLogging(useTableColumns.name);

  const exportFile = useCallback(
    (index: number | undefined) => {
      if (index === undefined) {
        return;
      }

      if (!tableName) {
        logger.error('Table name is not defined, can not export');
        return;
      }
      const exportData = props?.transformExportData
        ? props.transformExportData(tableData)
        : tableData;

      getCsv(exportData, tableName, index === 1).catch(e => {
        logger.error(e);
      });
    },
    [logger, props, tableData, tableName],
  );

  /**
   * Convenience component for the export dropdown
   * @param {React.CSSProperties} style for the dropdown
   * @returns {ExportDropDown}
   */
  // NOT BEING USED IN TELEFOOTX
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const ExportDropDown = ({ style }: { style?: React.CSSProperties }) => {
    return (
      <DropDownSelect
        setSelectionIndex={exportFile}
        options={exportOptions}
        labels={exportOptions}
        placeholder={'Export'} // TODO: does this button exist in telefootx portal?
        style={style}
        menuAlignment="right"
      />
    );
  };

  return {
    columnHelper,
    fetchedData,
    setFetchedData,
    tableData,
    setTableData,
    totalEntries,
    setTotalEntries,
    search,
    setSearch,
    sorting,
    setSorting,
    filters,
    setFilters,
    rowSelection,
    setRowSelection,
    tableName,
    setTableName,
    lastSynced,
    setLastSynced,
  };
};
