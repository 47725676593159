import { type StyleSheet } from '../../../../providers/ThemeProvider';

export default (): StyleSheet => ({
  popupContainer: {
    marginTop: '5%',
    marginBottom: '5%',
    textAlign: 'center',
  },
  popupButton: {
    maxWidth: '40%',
  },
});
